.ant-checkbox-input {
  border-color: blueviolet !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem;
}

.ant-modal-footer {
  text-align: center !important;
}

.ant-btn-default {
  display: none !important;
}

.ant-btn-primary {
  height: 66px;
  width: 329px;
  border-radius: 12px;
  background: linear-gradient(99.36deg, #2cb6c7 15.61%, #7b2dea 111.02%);
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}
@media only screen and (max-width: 600px) {
  .ant-btn-primary {
    width: 250px;
  }
  .ant-modal-content {
    padding: 0.6rem !important;
  }
}
/* .rc-anchor-normal .rc-anchor-content{
    width: 180px !important;
}

.rc-anchor-logo-portrait{
    margin: 10px 0 0 0 !important;
} */
