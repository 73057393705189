*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

@font-face {
  font-family: mRegular;
  src: url(/public/Mortend-Bold.ttf );
}

/* @font-face {
  font-family: fRegular;
  src: url(/public/Faktum-Regular.otf); 
} */
